<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="font-weight-black">
      <span>
        Pessoas
        <v-progress-circular
          v-if="carregandoRemoverPessoa || carregandoAdicionarPessoa || carregandoBuscarPessoas"
          indeterminate
          :size="15"
          :width="2"
          color="primary"
          class="ml-2"
        />
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row no-gutters>
        <v-col class="no-gutters pb-2">
          <i
            v-if="Object.keys(pessoas).length <= 0 && !carregandoBuscarPessoas && !carregandoAdicionarPessoa"
            class="grey--text"
          >
            <small>Nenhuma pessoa adicionada</small>
          </i>
          <v-chip
            v-for="(pessoa, key) in pessoas"
            v-else
            :key="key"
            class="my-1 mr-1"
            close
            label
            close-icon="mdi-delete"
            outlined
            @click="abrirDialogEditarPessoa(pessoa)"
            @click:close="removerPessoa(pessoa)"
          >
            {{ pessoa.nome }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!seeOnly">
        <v-col class="no-gutters">
          <v-btn
            outlined
            small
            color="success"
            :disabled="!ocorrenciaId || carregandoBuscarPessoas || carregandoAdicionarPessoa"
            @click="mostrarDialogPesquisarPessoa = true"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>

          <dialog-pesquisar-pessoa
            :mostrar="mostrarDialogPesquisarPessoa"
            @fechar="mostrarDialogPesquisarPessoa = false"
            @change="adicionarPessoa"
          />

          <dialog-pessoa
            :mostrar="mostrarDialogEditarPessoa"
            :pessoa-id="pessoaIdEditar"
            @fechar="fecharDialogEditarPessoa"
            @salvar:dados="fecharDialogEditarPessoa"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {
    components: {
      DialogPesquisarPessoa: () => import('@/components/dialog/DialogPesquisarPessoa'),
      DialogPessoa: () => import('@/components/dialog/pessoa/DialogPessoa'),
    },

    props: {
      ocorrenciaId: {
        type: Number,
        default: 0,
      },
      seeOnly: {
      type: Boolean,
      default:false,
    },
    },

    data () {
      return {
        mostrarDialogEditarPessoa: false,
        mostrarDialogPesquisarPessoa: false,
        carregandoBuscarPessoas: false,
        carregandoAdicionarPessoa: false,
        carregandoRemoverPessoa: false,
        pessoaIdEditar: null,
        pessoas: [],
      }
    },

    mounted () {
      this.buscarPessoas()
    },

    methods: {
      abrirDialogEditarPessoa (pessoa) {
        this.pessoaIdEditar = pessoa.id
        this.mostrarDialogEditarPessoa = true
      },

      fecharDialogEditarPessoa () {
        this.pessoaIdEditar = null
        this.mostrarDialogEditarPessoa = false
        this.buscarPessoas()
      },

      async buscarPessoas () {
        if (!this.ocorrenciaId) return

        try {
          this.carregandoBuscarPessoas = true
          const resposta = await ocorrenciasApi.buscarPessoas(this.ocorrenciaId)
          this.pessoas = resposta.data
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscarPessoas = false
        }
      },

      async adicionarPessoa (pessoa) {
        try {
          this.mostrarDialogPesquisarPessoa = false
          this.carregandoAdicionarPessoa = true

          const resposta = await ocorrenciasApi.adicionarPessoa({
            ocorrencia_id: this.ocorrenciaId,
            pessoa_id: pessoa.id,
          })

          this.pessoas = resposta.data
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoAdicionarPessoa = false
        }
      },

      async removerPessoa (pessoa) {
        try {
          this.carregandoRemoverPessoa = true
          const resposta = await ocorrenciasApi.removerPessoa(this.ocorrenciaId, pessoa.id)
          this.pessoas = resposta.data
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoRemoverPessoa = false
        }
      },
    },
  }
</script>
